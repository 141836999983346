<template>
  <b-card>
    <b-row class="">
      <b-col class="col-md-12">
        <h2
            class="text-blue font-weight-bold col-md-10 col-lg-10 col-xl-11 mt-1"
        >
          {{ $t(title) }}
        </h2>
      </b-col>
    </b-row>
    <Table
        large
        :fields="fields"
        :url="url"
        :showSearch="true"
        :body="{}"
        responsive="sm"
    >
      <template #cell(id)="data">
        <b-link  :to="'/invoice/' + data.item.id" style="width:55px;" class="badge badge-light-primary">I-{{ data.item.id }}</b-link>
      </template>
      <template #cell(seller)="data">
        <b-link v-if="data.item.company" :to="'/company/' + data.item.company.id"
        >{{ data.item.company.name }}
        </b-link>
      </template>
      <template #cell(amount)="data">
        {{ data.item.amount | formatAmount }}
      </template>
      <template #cell(debtor)="data">
        <b-link v-if="data.item.debtor" :to="'/company/' + data.item.debtor.id"
        >{{ data.item.debtor.name }}
        </b-link>
        <div v-else>-</div>
      </template>

      <template #cell(investor)="data">
        <p v-if="data.item.investor">{{ data.item.investor }}</p>
        <div v-else>-</div>
      </template>

      <template #cell(currency)="data">
        <span>{{ data.item.currency.code }} </span>
      </template>
      <template #cell(invoice_number)="data">
        <span v-if="data.item.invoice_number"
        >{{ data.item.invoice_number }}
        </span>
        <span v-else> - </span>
      </template>
      <template #cell(created_at)="data">
        {{ data.item.created_at | formatDate }}
      </template>
      <template #cell(hide_from_market)="data">
        <span class="text-danger" v-if="data.value == 1">{{ $t("hidden") }}</span>
        <span class="text-success" v-else-if="data.value == 0">{{ $t("shown")}}</span>
      </template>
      <template #cell(status)="data">
        <span class="text-secondary" v-if="data.value == 0"
        >{{ $t("Open") }}
        </span>
        <span class="text-success" v-if="data.value == 1">{{
          $t("active_marketplace_offer")
        }}</span>
        <span class="text-secondary" v-if="data.value == 2">
            <template v-if="global_settings && global_settings.status">
                {{ $t("send_offer") }}
            </template>

            <template v-else>
                {{ $t("rejected") }}
            </template>
        </span>
        <span class="text-secondary" v-if="data.value == 3">{{
          $t("scoring_required")
        }}</span>
        <span class="text-secondary" v-if="data.value == 4">{{
          $t("scored")
        }}</span>
        <!--data.item.recourse == 0 ? (data.item.is_reverse == 1 ? $t("supplier_confirmation_required") : $t("debtor_confirmation_required")) : "-"-->
        <span class="text-secondary" v-if="data.value == 5">{{
          $t("click_sell_invoice")
        }}</span>
        <span class="text-secondary" v-if="data.value == 6">{{
          data.item.recourse == 0 ? (data.item.is_reverse == 1 ? $t("supplier_confirmation_required") : $t("debtor_confirmation_required")) : "-"
        }}</span>
        <span class="text-secondary" v-if="data.value == 7">{{
          $t("rejected")
        }}</span>
        <span class="text-secondary" v-if="data.value == 8">{{
          $t("soon_goes_active")
        }}</span>
        <span class="text-secondary" v-if="data.value == 9">{{
          $t("in_review")
        }}</span>
        <span class="text-secondary" v-if="data.value == 10">{{
          $t("sold")
        }}</span>
        <span class="text-secondary" v-if="data.value == 11">{{
          $t("settled")
        }}</span>
        <span class="text-secondary" v-if="data.value == 12">{{
          $t("send_offer")
        }}</span>
      </template>

      <template #cell(action)="data">
        <!-- <b-button
          class="mr-1 button-top-m mobile"
          @click="downloadPdf(data.item.id, data.item)"
          variant="outline-success"
          size="sm"
        >
          <FeatherIcon icon="DownloadIcon"></FeatherIcon>
        </b-button> -->

        <b-button
            v-b-tooltip.hover :title="$t('read_pdf')"
            class="mr-1 button-top-m web"
            @click="readPdf(data.item.id, data.item)"
            variant="outline-success"
            size="sm"
        >
          <FeatherIcon icon="BookOpenIcon"></FeatherIcon>
        </b-button>
      </template>

      <template #cell(silent)="data">
        <span v-if="data.item.recourse == 0">- </span>
        <span v-if="data.item.recourse == 1">Yes</span>
      </template>
      <template #cell(recourse)="data">
        <span v-if="data.value == 0">- </span>
        <span v-if="data.value == 1">Yes</span>
      </template>
      <template #cell(flash_sale)="data">
        <span v-if="data.value == 0">- </span>
        <span v-if="data.value == 1">Yes</span>
      </template>
      <template #cell(custom)="">
        <!--        <b-button size="sm"-->
        <!--                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"-->
        <!--                  v-b-modal.invoiceUpdate-->
        <!--                  variant="primary"-->
        <!--                  @click="updateInvoiceId=data.item">-->
        <!--          <feather-icon icon="EditIcon"></feather-icon>-->
        <!--        </b-button>-->
        <!--        <b-button size="sm" class="ml-1"-->
        <!--                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"-->
        <!--                  v-b-modal.approveModal-->
        <!--                  variant="outline-primary"-->
        <!--                  @click="changeModalInfo('approve','Approve','Are you sure?', data.item.id)">-->
        <!--          <feather-icon icon="CheckIcon"></feather-icon>-->
        <!--        </b-button>-->
        <!--        <b-button variant="outline-danger" size="sm" class="ml-1 mr-1"-->
        <!--                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"-->
        <!--                  v-b-modal.approveModal-->
        <!--                  @click="changeModalInfo('decline','Decline','Are you sure?', data.item.id)">-->
        <!--          <feather-icon icon="XIcon"></feather-icon>-->
        <!--        </b-button>-->
        <!--        <b-button variant="danger" size="sm"-->
        <!--                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"-->
        <!--                  v-b-modal.approveModal-->
        <!--                  @click="changeModalInfo('delete','Delete','Are you sure?', data.item.id)">-->
        <!--          <feather-icon icon="TrashIcon"></feather-icon>-->
        <!--        </b-button>-->
      </template>
    </Table>

    <!--    <CustomLoader :show="isLoading" />-->
    <ApproveModal :textContent="modalText" :title="modalTitle" />
    <!--    <InvoiceUpdate :invoice="updateInvoiceId" :companies="companies" />-->
  </b-card>
</template>

<script>
  import {
    BTable,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BPagination,
    BTr,
    BTh,
    BButton,
    BLink,
  } from "bootstrap-vue";
  import fileDownload from "js-file-download";
  import ApproveModal from "@/components/Common/ApproveModal";
  import InvoiceUpdate from "@/components/Invoice/InvoiceUpdate";
  import CustomLoader from "@/components/Common/CustomLoader";
  import Table from "@/components/Common/Table2";
  import Ripple from "vue-ripple-directive";

  export default {
    name: "Invoices",
    components: {
      ApproveModal,
      Table,
      BTable,
      BRow,
      BCol,
      BFormGroup,
      BFormInput,
      BPagination,
      BTr,
      BTh,
      BButton,
      BLink,
      CustomLoader,
      InvoiceUpdate,
    },
    directives: {
      Ripple,
    },
    computed: {
      rows() {
        return this.totalItems;
      },
    },
    data() {
      return {
        title: "invoices_management",
        companies: [],
        modalText: null,
        modalTitle: null,
        options: null,
        fields1: [
          { label: "title", align: "start", key: "title", sortable: false },
          { label: "", customSlot: false, key: "custom", class: "text-right" },
        ],
        fields: [
          { label: "created_at", align: "start", key: "created_at", sortable: true },
          { text: "Reference", align: "start", key: "id", sortable: true },
          { text: "Seller", align: "start", key: "seller", sortable: true },
          { text: "Debtor", align: "start", key: "debtor", sortable: true },
          { text: "Investor", align: "start", key: "investor", sortable: true },
          {
            text: "Invoice Nr",
            align: "start",
            key: "invoice_number",
            sortable: true,
          },
          { text: "Currency", align: "start", key: "currency", sortable: true },
          {
            text: "Invoice Amount",
            align: "start",
            key: "amount",
            sortable: true,
          },
          {
            text: "Due date",
            align: "start",
            key: "due_date",
            sortable: true,
          },
          { text: "Silent", align: "start", key: "silent", sortable: true },
          { text: "Recourse", align: "start", key: "recourse", sortable: true },
          {
            text: "Flash Sale",
            align: "start",
            key: "flash_sale",
            sortable: true,
          },
          { text: "Market Visibility", key: "hide_from_market", customSlot: true, sortable: true },
          { text: "Status", key: "status", customSlot: true, sortable: true },
          { text: "", customSlot: true, key: "action", class: "" },
        ],
        items: [],
        search: "",
        page: 1,
        pageCount: 0,
        itemsPerPage: 12,
        status: [],
        isLoading: false,
        totalItems: 0,
        updateInvoiceId: null,
        url: "/invoice/admin/list",
        approveModalAction: null,
        item: null,
        showPdfViwer: false,
        loadPdf: true,
        type: "",
        base64: "",
        source: null,
        embedType: "",
        src: "",
      };
    },
    created() {
      this.getApiData();
      this.getCompanies();
      this.getGlobalSetting();
    },
    methods: {

      async getGlobalSetting() {
        await this.$http.post("/global_settings").then((res) => {
          if (res) {
            if(res.data.value) {
              this.global_settings = res.data.value;
              const get_offer_later = this.global_settings.filter(item => item.type === 'get_offer_later');
              this.global_settings = get_offer_later
            }
          }
        })
      },

      readPdf(id, item) {
        this.item = item;
        this.$store.commit("app/ON_SIDEBAR_CLICK", false);
        this.loadPdf = true;
        this.type = "";
        this.base64 = "";
        this.src = "";
        this.embedType = "";
        this.$http
            .post(
                "/invoice/admin/download/" + id,
                {},
                {
                  responseType: "blob",
                }
            )
            .then((response) => {
              fileDownload(response.data, item.title);
            })
            .catch((error) => {});
      },
      getCompanies() {
        this.$http.post("/company/admin/allList", {}).then((response) => {
          if (response.data.status == 200) {
            this.companies = response.data.value;
          }
        });
      },
      changeModalInfo(action, title, text, id) {
        this.approveModalAction = action;
        this.modalText = text;
        this.modalTitle = title;
        this.idToChange = id;
      },
      callApproveModalAction() {
        if (this.approveModalAction == "approve") {
          this.changeStatus(this.idToChange, "approve");
        }
        if (this.approveModalAction == "decline") {
          this.changeStatus(this.idToChange, "decline");
        }
        if (this.approveModalAction == "delete") {
          this.changeStatus(this.idToChange, "delete");
        }
      },
      changeStatus(id, status) {
        this.isLoading = true;
        let s = 0;
        if (status == "approve") {
          s = 1;
        }
        if (status == "decline") {
          s = 2;
        }
        if (status == "delete") {
          s = 99;
        }
        this.$http
            .post("/invoice/admin/update/" + id, { status: s })
            .then((response) => {
              this.getApiData();
              this.isLoading = false;
            })
            .catch((error) => {
              this.isLoading = false;
            });
      },
      downloadPdf(id, item) {
        this.$http
            .post(
                "/invoice/admin/download/" + id,
                {},
                {
                  responseType: "blob",
                }
            )
            .then((response) => {
              fileDownload(response.data, item.title);
              this.isLoading = false;
            })
            .catch((error) => {
              this.isLoading = false;
            });
      },
      getApiData() {
        this.isLoading = true;
        this.items = [];
        this.$http
            .post("/invoice/admin/list?page=" + this.page, {
              status: this.status,
              options: null,
              filters: this.filters,
              search: this.search,
            })
            .then((response) => {
              if (response.data.status == 200) {
                this.items = response.data.value.data;
                this.totalItems = response.data.value.total;
                this.page = response.data.value.current_page;
                this.isLoading = false;
              }
            })
            .catch((error) => {
              this.isLoading = false;
            });
      },
    },
  };
</script>

<style scoped>
</style>
